import { DecimalPipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from "@angular/common";
import { HttpClientModule } from '@angular/common/http';
import localeBr from '@angular/common/locales/pt';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { ChartModule } from 'primeng/chart';
import { CalendarModule, CardModule, ConfirmationService, ConfirmDialogModule, GrowlModule } from 'primeng/primeng';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AraguaiaSharedModule } from './araguaia-shared.module';
import { SetupService } from './configuracoes/setup/setup.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardService } from './dashboard/dashboard.service';
import { DuplicataReceberService } from './financeiro/duplicata-receber/duplicata-receber.service';
import { ParcelaReceberService } from './financeiro/duplicata-receber/parcela-receber.service';
import { LoginComponent } from "./login/login.component";
import { UsuarioService } from "./seguranca/usuario/usuario.service";
import { AuthGuard } from "./shared/auth/auth.guard";
import { AuthService } from "./shared/auth/auth.service";
import { GlobalsVariablesService } from './shared/service/global-variables.service';
import { MessageService } from './shared/service/message.service';
import { RowMaskService } from './shared/service/row-mask.service';
import { SelecaoDataTableService } from "./shared/service/selecao-data-table.service";
import { ViewComponent } from './view/view.component';


// the second parameter 'fr' is optional 
registerLocaleData(localeBr, 'pt');

export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    GrowlModule,
    CalendarModule,
    AppRoutingModule,
    ConfirmDialogModule,
    CardModule,
    ChartModule,
    AraguaiaSharedModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    ViewComponent,
    DashboardComponent
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'pt' },
    DashboardService,
    DecimalPipe,
    RowMaskService,
    GlobalsVariablesService,
    SetupService,
    AuthService,
    AuthGuard,
    CookieService,
    MessageService,
    UsuarioService,
    ConfirmationService,
    SelecaoDataTableService,
    ParcelaReceberService,
    AppInitService,
    DuplicataReceberService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {

} 