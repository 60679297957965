import { GenericModel } from '../../shared/model/generic.model';


export class ProdutoConsulta extends GenericModel {
    public id: number;
    
    public codigoInterno     : number;
    public codigoBarra       : string;
    public outroCodigo       : string;
    public codigoOriginal    : string;
    public codigoReferencia  : string;    
    public descricao         : string;
    public aplicacao         : string;
    public tipoProduto       : string;               
    public unidadeId         : number;
    public unidadeSigla      : string;
    public unidadeTipoMedida : string;
    public cofinsVenda       : number;
    public pisVenda          : number;
    public estoqueId         : number;
    public saldoEstoque      : number;
    public saldoPendencia    : number;
    public precoNota         : number;
    public valorCompra       : number;
    public icmsDevido        : number;
    public outrosImpostos    : number;
    public percentualRentabilidade: number;
    public percentualCustoOperacional: number;
    public valorVenda        : number;
    
}
