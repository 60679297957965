import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SearchParams } from '../../service/generic.service';
import { KEY_CODE } from '../../constantes/key-codes';
import { LazyLoadEvent } from 'primeng/components/common/api';
import { QtdeRegistrosDataTable } from '../../constantes/qtde-registros-data-table';
import { TipoStatus } from '../../constantes/tipo-status';
import { PDVPagamentoService } from '../../../financeiro/pdv-pagamento/pdv-pagamento.service';

@Component({
    selector: 'p-tabView-pesquisa-avancada-pdv-pagamento',
    styleUrls: ['./pesquisa-avancada-pdv-pagamento.component.css'],
    templateUrl: './pesquisa-avancada-pdv-pagamento.component.html'
})
export class PesquisaAvancadaPDVPagamentoComponent {

    public pesquisaFormPDVPagamento :FormGroup;
    public searchPDVPagamento : SearchParams = new SearchParams();
    public navigation : boolean = false;
    public quantidadePesquisa : number = 0;
    public selectedDefault = [];
    public selectedPDVPagamento = Object();
    public selectedPDVPagamentoAux = Object(); 
    public index : number;

    @Input()  tipoPesquisaPDVPagamento;
    @Output() retornoTipoPesquisa = new EventEmitter();
    @Output() retornoPDVPagamentoSelecionado = new EventEmitter();

    constructor(public fb: FormBuilder,
                public _pdvPagamentoService: PDVPagamentoService) {
        this.buildPesquisaPDVPagamento();
    }

    buildPesquisaPDVPagamento(): void {
        this.pesquisaFormPDVPagamento = this.fb.group({
            'id':[''],            
            'descricao': ['']            

        });
    }

    loadPDVPagamentos(event) {
        //Calcula a página atual
        let page = 0; 
        this.quantidadePesquisa++;
        if (event.first > 0) {
            page = event.first / event.rows;
        }
        
        this.searchPDVPagamento.page = page;
        this.searchPDVPagamento.size = QtdeRegistrosDataTable.QTDE;
        this.searchPDVPagamento.filters["descricao"] = this.pesquisaFormPDVPagamento.get('descricao').value;
        this.searchPDVPagamento.filters['status'] = TipoStatus.ATIVO;
        this.searchPDVPagamento.sorting[event.sortField] = event.sortOrder == -1 ? 'desc' : 'asc';
        this._pdvPagamentoService.list(this.searchPDVPagamento);
        
        
        if(page >= 0){
            
            //Fica ouvindo o resultado da seleção do Produto Default
            var interval = setInterval(() =>{

                this.selectedDefault = this._pdvPagamentoService.pageResult.content;
                
                if(this.selectedDefault.length > 0 ){
                    clearInterval(interval);
                    // Set default row selection in first row DataTable Produtos
                    this.selectedPDVPagamento = this.selectedDefault['0'];
                    this.index = 0;
                    this.selectedFirstRow();
                }
            },600); 
        }
    }

    onPesquisaPDVPagamento(event) {
        /* Event diferente de SHIFT, TAB e SHIFT + TAB */
        if(event.keyCode != KEY_CODE.TECLA_SHIFT){
            if(event.keyCode != KEY_CODE.TECLA_TAB && event.keyCode != KEY_CODE.TECLA_SETA_PARA_BAIXO && event.keyCode != KEY_CODE.TECLA_ENTER){
                if(event.shiftKey != true && event.keyCode != KEY_CODE.TECLA_TAB){
                    this.navigation = false;
                    this.loadPDVPagamentos(event); 
                }
            }else{
                if(event.keyCode == KEY_CODE.TECLA_TAB){
                    let table:any = '.selected-line-pdv-pagamento';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    event.preventDefault();
                }
                if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_BAIXO){
                    let table:any = '.selected-line-pdv-pagamento';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    event.preventDefault();
                }
                if(event.keyCode == KEY_CODE.TECLA_ENTER){
                    let table:any = '.selected-line-pdv-pagamento';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    this.onSelectedPDVPagamento(event);
                    event.preventDefault();
                }
            }
        }
    }

    onClickPDVPagamento(event){
        let button;
        let alvo = event.target;
        let buttonSelected;
        this.selectedPDVPagamento = this.selectedPDVPagamentoAux['0'];

        switch(alvo.nodeName){
            case 'BUTTON':

                button = alvo.parentElement;
                button.click();

            break;
            case 'I':
                button = alvo.parentNode;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
            break;
            case 'DIV':
                button = alvo.parentNode.parentNode.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
            break;
            case 'TD' :

                button = alvo.parentNode.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
            break;
            case 'SPAN' :

                button = alvo.parentNode.parentElement.firstElementChild.lastElementChild;
                if(button.nodeName == 'SPAN') button = alvo.parentNode.parentNode.parentElement.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
            break;
            case 'P-TABLE':

                if(alvo.firstElementChild.firstElementChild.firstElementChild != null){
                    //Primeiro clique : O evento possui a navegação indicada
                    button = alvo.firstElementChild.firstElementChild.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild;
                    button.focus();
                    
                }else{
                    //Segundo clique em diante : O evento não possui a primeira navegação indicada e sim esta
                    button = alvo.firstElementChild.firstElementChild.nextElementSibling.nextElementSibling.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild;
                    button.focus();
                }
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                button.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
            break;
            default:

        }
    }

    onKeydownPDVPagamento(event) {
        let buttonSelected;
        let proxButton;
        let prevButton;

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_BAIXO) {
            event.preventDefault();
            if (document.getElementById('irmao')){
                if(event.target.parentElement.parentElement.nextElementSibling){
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }        
            }else{
                if(event.target.parentElement.parentElement.nextElementSibling){
                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            } 
        }

        /* KeyCode = seta para a cima */
        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_CIMA) {
            event.preventDefault();
            if (document.getElementById('irmao')){                              
                if(event.target.parentElement.parentElement.previousElementSibling){
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
             
            }else{
                if(event.target.parentElement.parentElement){
                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }      
        }

        /* KeyCode = Shift + TAB */
        if(event.shiftKey == true && event.keyCode == KEY_CODE.TECLA_TAB){
            event.preventDefault();
            if (document.getElementById('irmao')){                              
                if(event.target.parentElement.parentElement.previousElementSibling){
                   
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }else{
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";
                }
            }else{
                if(event.target.parentElement.parentElement.previousElementSibling){
                   
                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }     
            return;
        }
        
        /* KeyCode = TAB */
        if(event.keyCode === KEY_CODE.TECLA_TAB){
            event.preventDefault();
            if (document.getElementById('irmao')){
                if(event.target.parentElement.parentElement.nextElementSibling){

                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }else{
                if(event.target.parentElement.parentElement.nextElementSibling){ 

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line-pdv-pagamento");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent-pdv-pagamento';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
             }
        }

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_DIREITA){
            let prox = document.getElementsByClassName('ui-paginator-next');
            prox[4].id = 'proxPDVPagamento';
            document.getElementById('proxPDVPagamento').click();
        }

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_ESQUERDA){
            let prev = document.getElementsByClassName('ui-paginator-prev');
            prev[4].id = 'prevPDVPagamento';
            document.getElementById('prevPDVPagamento').click();
        }

        /* Selecionar produto */
        if(event.keyCode == KEY_CODE.TECLA_ENTER){
            this.onSelectedPDVPagamento(event);
        }
    }

    //EVENT EMITTER
    onSelectedPDVPagamento(event):void{
        this.retornoTipoPesquisa.emit({pesquisa:true});
        this.selectedPDVPagamento = this.selectedPDVPagamentoAux['0'];
        this.retornoPDVPagamentoSelecionado.emit(this.selectedPDVPagamento);
    }

    //EVENT EMITTER
    retornarPesquisaSimples(){
        this.retornoTipoPesquisa.emit({pesquisa:false});
    }

    selectedFirstRow(){
        let arr = document.getElementsByClassName('btn-transparent-pdv-pagamento');
        arr[0].className = "btn-transparent-pdv-pagamento selected-line-pdv-pagamento";
        arr[0].id = "primeiro-item-pdv-pagamento";
        document.getElementById("primeiro-item-pdv-pagamento").click();
        document.getElementById("inputPesquisaPDVPagamento").focus();
    }

}