import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/components/common/api';
import { debounceTime, map } from 'rxjs/operators';
import { ProdutoConsulta } from '../../../comercial/pedido/produto-consulta.model';
import { ProdutoCTAModel } from '../../../comercial/pedido/produto-cta-model';
import { ProdutoValida } from '../../../estoque/produto/produto-valida.model';
import { Produto } from '../../../estoque/produto/produto.model';
import { ProdutoService } from '../../../estoque/produto/produto.service';
import { UnidadeService } from '../../../estoque/unidade/unidade.service';
import { KEY_CODE } from '../../constantes/key-codes';
import { QtdeRegistrosDataTable } from '../../constantes/qtde-registros-data-table';
import { TipoStatus } from '../../constantes/tipo-status';
import { SearchParams } from '../../service/generic.service';
import { MessageService } from '../../service/message.service';

@Component({
    selector: 'p-tabView-pesquisa-avancada-produto-pedido-online',
    styleUrls: ['./pesquisa-avancada-produto-pedido-online.component.css'],
    templateUrl: './pesquisa-avancada-produto-pedido-online.component.html'
})
export class PesquisaAvancadaProdutoPedidoOnlineComponent implements AfterViewInit{

    public searchProduto: SearchParams = new SearchParams();
    public selectedProduto: ProdutoCTAModel = new ProdutoCTAModel();
    public selectedProdutoAux: ProdutoCTAModel = new ProdutoCTAModel();
    public selectedProdutoEquivalente = Object();
    public modelProdutoConsulta: ProdutoConsulta = new ProdutoConsulta();
    public modelProdutoValido: ProdutoValida = new ProdutoValida();
    public modelProduto: ProdutoCTAModel = new ProdutoCTAModel();
    public pesquisaForm: FormGroup;
    public equivalentesForm: FormGroup;
    public navigation: boolean = false;
    public quantidadePesquisa: number = 0;
    public selectedDefault = [];
    private selectedDefaultEquivalentes = [];
    public index: number;
    public onProduto: boolean = false; //utilizado no HTML
    public onProdutoEquivalente: boolean = false;
    public descricaoProduto: String = 'Produto';
    public pesquisaProdutoEstoque: number;
    public pesquisaProdutoEstoquePendente: number;
    public scrollable = true;
    public totalRecords = 100;
    public produtos: Array<any> = null;
    public cols: any[];
    public mostrarInformacoes = false;
    public mostrarImagens = true;
    public imagemSelecionada = 0;
    @Input() lojaId;
    @Input() quantLojas;
    @Input() favorecidoId;
    @Input() listaPrecoId;
    @Output() retornoTipoPesquisa = new EventEmitter();
    @Output() retornoProdutoSelecionado = new EventEmitter();
    @Output() retornoProdutoEquivalenteSelecionado = new EventEmitter();


    constructor(public fb: FormBuilder,
        public _produtoService: ProdutoService,
        public _unidadeservice: UnidadeService,
        public _messages: MessageService
    ) {
        this.buildPesquisaForm();
        this.buildEquivalentesForm();
        this.buildObservable();

        let interval = setInterval(
            () => {

                if(this.listaPrecoId != null && this.listaPrecoId != undefined && this.lojaId != null && this.lojaId){
                    console.log('fazendo pequisa constructor')
                    this.searchProduto.filters['pesquisa'] = this.pesquisaForm.get('pesquisaProduto').value;
                    this.searchProduto.filters['idListaPreco'] = this.listaPrecoId;
                    this.searchProduto.filters['idLoja'] = this.lojaId

                    console.log('pesquisando com ')
                    console.log( this.searchProduto.filters['pesquisa'] )
                    this._produtoService.searchByVendaPedidoOnline(this.searchProduto)
                        .subscribe(
                            res => {

                                this.limparLista();
                                this.produtos = res.content;

                            }
                        );
                        clearInterval(interval)
                }

            }, 200
        );

    }

    buildPesquisaForm(): void {
        this.pesquisaForm = this.fb.group({
            'id': [''],
            'pesquisaProduto': [''],
            'aplicacao': [''],
            'descricao': [''],
            'unidadeSigla': [''],
            'categoriaDescricao': [''],
            'origemProdutoDescricao': [''],
            'ncmDescricao': [''],
            'codigoInterno': [''],
            'codigoBarra': [''],
            'observacao': [''],
            'observacaoWeb': ['']
        });
    }

    buildEquivalentesForm(): void {
        this.equivalentesForm = this.fb.group({
            'equivalenteAplicacao': [''],
            'equivalenteDescricao': [''],
            'equivalenteCodigoReferencia': [''],
            'equivalenteCodigoOriginal': ['']
        });
    }


    buildObservable(): void{
        this.pesquisaForm.get('pesquisaProduto').valueChanges
        .pipe(
            debounceTime(600),
            map(res => {
                    this.limparLista();
                }
            ),
        ).subscribe(
            res => {



                if(this.pesquisaForm.get('pesquisaProduto').value.length < 3){
                    return;
                }

                this.searchProduto.filters['pesquisa'] = this.pesquisaForm.get('pesquisaProduto').value;
                this.searchProduto.filters['idListaPreco'] = this.listaPrecoId;
                this.searchProduto.filters['idLoja'] = this.lojaId

                console.log('pesquisando com ')
                console.log( this.searchProduto.filters['pesquisa'] )
                this._produtoService.searchByVendaPedidoOnline(this.searchProduto)
                    .subscribe(
                        res => {
                            console.log('fazendo pequisa value chamges')
                            console.log(res)
                            if(res.content.length == 0){
                                // alert(' Não foram encontrados resultados para sua busca')
                                // var element = document.documentElement;
                                // element.requestFullscreen();
                            }

                            this.limparLista();
                            this.produtos = res.content;

                        }
                    );
            }
        );
    }

    limparLista() {

        this.produtos = [];


        let scroll = document.querySelector('.ui-table-scrollable-body');


        if(this._produtoService.pageResult.totalElements < 10){
            this.totalRecords = this._produtoService.pageResult.totalElements;
            scroll.classList.add('scroll-remove');
        }
        else{
            scroll.classList.remove('scroll-remove');
            this.totalRecords = 500;
        }



        scroll.scrollTop = 0;

    }

    loadProdutos(event: LazyLoadEvent) {



        //Calcula a página atual
        let page = 0;
        this.quantidadePesquisa++;

        if (event.first > 0) {
            page = event.first / event.rows;
        }
        this.searchProduto.page = page;
        this.searchProduto.size = 20;
        this.searchProduto.filters["pesquisa"] = this.pesquisaForm.get('pesquisaProduto').value;
        this.searchProduto.filters['status'] = TipoStatus.ATIVO;
        this.searchProduto.sorting[event.sortField] = event.sortOrder == -1 ? 'desc' : 'asc';
        this.searchProduto.filters['pesquisa'] = this.pesquisaForm.get('pesquisaProduto').value;
        this._produtoService.searchByVendaPedidoOnline(this.searchProduto)
            .subscribe(
                res => {

                    this.produtos = res.content;
                }
            );



    }

    onPesquisaProduto(event) {
        /* Event diferente de SHIFT, TAB e SHIFT + TAB */
        if (event.keyCode != KEY_CODE.TECLA_SHIFT) {
            if (event.keyCode != KEY_CODE.TECLA_TAB && event.keyCode != KEY_CODE.TECLA_SETA_PARA_BAIXO && event.keyCode != KEY_CODE.TECLA_ENTER) {
                if (event.shiftKey != true && event.keyCode != KEY_CODE.TECLA_TAB) {
                    this.navigation = false;
                    this.loadProdutos(event);
                }
            } else {
                if (event.keyCode == KEY_CODE.TECLA_TAB) {
                    let table: any = '.selected-line';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    event.preventDefault();
                }
                if (event.keyCode == KEY_CODE.TECLA_SETA_PARA_BAIXO) {
                    let table: any = '.selected-line';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    event.preventDefault();
                }
                if (event.keyCode == KEY_CODE.TECLA_ENTER) {
                    let table: any = '.selected-line';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    // this.onSelect(event, null, 1);
                    event.preventDefault();
                }
            }
        }
    }

    //EVENT EMITTER
    //selecionar elemento no dataTable e enviar a seleção para a Tela de Pesquisa Simples
    // Em caso de duplo clique na tabela, enviar a quantidade 1 por padrão
    // No caso de clique no botão, pega a quantidade digitada pelo usuário
    onSelect(event, row, quantidade): void {

        if (row == null) {
            this.selectedProduto = this.selectedProdutoAux['0'];
        }
        else {
            this.selectedProduto = row;
        }

        this.selectedProduto['quantidade'] = quantidade;
        this.pesquisaForm.get('observacao').setValue(this.selectedProduto.observacao);

        if (this.selectedProduto != null) {
            if (this.favorecidoId == null) {
                this._messages.warn("Insira o nome do Cliente antes de selecionar o produto.");
                this.elementFocus(event, "#favorecidoPessoaNomeFantasia > span > input");
            } else {
                // this.pesquisaForm.get('pesquisaProduto').setValue('');
                this.retornoProdutoSelecionado.emit(this.selectedProduto);
            }
        } else {
            console.log("ERRO AO SELECIONAR O PRODUTO - Descrição do produto vazia");
        }
    }

    //EVENT EMITTER
    //selecionar produto equivalente no dataTable e enviar a seleção para a Tela de Pesquisa Simples (input:search)
    onSelectEquivalente(event): void {
        this.onProdutoEquivalente = true;
        if (this.selectedProdutoEquivalente['equivalenteDescricao'] != '') {
            if (this.favorecidoId == null) {
                this._messages.warn("Insira o nome do Cliente antes de selecionar o produto.");
                this.elementFocus(event, "#favorecidoPessoaNomeFantasia > span > input");
            } else {

                this._produtoService.findOne(Number(this.selectedProdutoEquivalente['equivalenteId'])).subscribe(model => {
                    this.selectedProdutoEquivalente = model;
                    this.retornoProdutoEquivalenteSelecionado.emit(this.selectedProdutoEquivalente);
                });
            }
        } else {
            console.log("ERRO AO SELECIONAR O PRODUTO EQUIVALENTE - Descrição do produto vazia");
        }
    }

    //EVENT EMITTER
    retornarPesquisaSimples() {
        this.retornoTipoPesquisa.emit({ fechar: true });
    }

    onClickProduto(event) {


        let button;
        let alvo = event.target;
        let buttonSelected;


        this.onProduto = true; //mostrar informações completas do produto
        this.onProdutoEquivalente = false; //se houver seleção do equivalente anteriormente, retirar suas informações da tela
        this.selectedProdutoEquivalente = new Produto; //reset produto equivalente selecionado
        this.selectedProduto = this.selectedProdutoAux['0'];
        this.pesquisaForm.get('observacao').setValue(this.selectedProduto.observacao);
        this.pesquisaForm.get('observacaoWeb').setValue(this.selectedProduto.observacaoWeb);

        console.log(this.selectedProduto)

        switch (alvo.nodeName) {
            case 'BUTTON':

                button = alvo.parentElement;
                button.click();

                break;
            case 'I':

                button = alvo.parentNode;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if (buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
                if (this.selectedProduto.produtoEquivalentes) {
                    this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
                }
                break;
            case 'DIV':

                button = alvo.parentNode.parentNode.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if (buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
                if (this.selectedProduto.produtoEquivalentes) {
                    this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
                }
                break;
            case 'TD':

                button = alvo.parentNode.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if (buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
                if (this.selectedProduto.produtoEquivalentes) {
                    this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
                }
                break;
            case 'SPAN':

                button = alvo.parentNode.parentElement.firstElementChild.lastElementChild;
                if (button.nodeName == 'SPAN') button = alvo.parentNode.parentNode.parentElement.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if (buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
                if (this.selectedProduto.produtoEquivalentes) {
                    this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
                }
                break;
            case 'P-TABLE':

                if (alvo.firstElementChild.firstElementChild.firstElementChild != null) {
                    //Primeiro clique : O evento possui a navegação indicada
                    button = alvo.firstElementChild.firstElementChild.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild;
                    button.focus();

                } else {
                    //Segundo clique em diante : O evento não possui a primeira navegação indicada e sim esta
                    button = alvo.firstElementChild.firstElementChild.nextElementSibling.nextElementSibling.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild;
                    button.focus();
                }
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                //buttonSelected = document.querySelector(".selected-line");
                //if(buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
                if (this.selectedProduto.produtoEquivalentes) {
                    // Comentei por enquanto
                    // this.loadEquivalente(this.selectedProduto.produtoEquivalentes[0].produtoId)
                    this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
                }

                break;
            default:


                if (this.selectedProduto.descricao != null) this.descricaoProduto = this.selectedProduto.descricao;

        }


    }

    focusQuantidade(quantidade: any): void {

    }

    onClickEquivalente(event) {
        let button;
        let alvo = event.target;

        this.onProduto = false; //se houver seleção do produto anteriormente, retirar suas informações da tela
        this.onProdutoEquivalente = true; //mostrar informações completas do produto equivalente
        if (this.onProdutoEquivalente == true) {
            console.log("Produto Equivalente Selecionado:", this.selectedProdutoEquivalente);
        }
        //console.log(alvo);
        switch (alvo.nodeName) {
            case 'SPAN':
                button = alvo.parentNode.parentNode.firstElementChild.lastElementChild.lastElementChild;
                button.focus();
                break;
            case 'TD':
                button = alvo.parentNode.firstElementChild.lastElementChild.lastElementChild;
                button.focus();
                break;
            case 'P-DATATABLE':
                button = alvo.firstElementChild.firstElementChild.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild.lastElementChild;
                button.focus();
                break;
        }
    }

    /**
     * Navegar no dataTable selecionando os elementos | SELECAO-DATA-TABLE-SERVICE.TS Melhorado e Adaptado para esta solução
     *
     */
    onKeydownDiv(event) {
        let buttonSelected;
        let proxButton;
        let prevButton;

        if (event.keyCode == KEY_CODE.TECLA_SETA_PARA_BAIXO) {
            event.preventDefault();
            if (document.getElementById('irmao')) {
                if (event.target.parentElement.parentElement.nextElementSibling) {
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if (buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            } else {
                if (event.target.parentElement.parentElement.nextElementSibling) {
                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if (buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }
        }

        if (event.keyCode == KEY_CODE.TECLA_SETA_PARA_CIMA) {
            event.preventDefault();
            if (document.getElementById('irmao')) {
                if (event.target.parentElement.parentElement.previousElementSibling) {
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if (buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }

            } else {
                if (event.target.parentElement.parentElement) {
                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if (buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }
        }

        if (event.shiftKey == true && event.keyCode == KEY_CODE.TECLA_TAB) {
            event.preventDefault();
            if (document.getElementById('irmao')) {
                if (event.target.parentElement.parentElement.previousElementSibling) {

                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if (buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                } else {
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";
                }
            } else {
                if (event.target.parentElement.parentElement.previousElementSibling) {

                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if (buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }
            return;
        }

        if (event.keyCode == KEY_CODE.TECLA_TAB) {
            event.preventDefault();
            if (document.getElementById('irmao')) {
                if (event.target.parentElement.parentElement.nextElementSibling) {

                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if (buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            } else {
                if (event.target.parentElement.parentElement.nextElementSibling) {

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if (buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }
        }

        if (event.keyCode == KEY_CODE.TECLA_SETA_PARA_DIREITA) {
            let prox = document.getElementsByClassName('ui-paginator-next');
            prox[2].id = 'proxProduto';
            document.getElementById('proxProduto').click();
        }

        if (event.keyCode == KEY_CODE.TECLA_SETA_PARA_ESQUERDA) {
            let prev = document.getElementsByClassName('ui-paginator-prev');
            prev[2].id = 'prevProduto';
            document.getElementById('prevProduto').click();
        }

        if (event.keyCode == KEY_CODE.TECLA_ENTER) {
            this.onSelect(event, null, 1);
        }
    }

    /**
     * Seleciona a primeira linha da tabela de produtos equivalentes quando
     * há passagem direta via TAB do último campo anterior a tabela de equivalentes
     *
     */
    onKeydownFirstTab(event) {
        if (this.selectedProdutoEquivalente.descricao == '') {
            //se não houver produto selecionado, então selecionar o primeiro da lista
            if (this.selectedProduto.produtoEquivalentes) {
                //preenche a lista com os equivalentes do produto selecionado
                this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
            }
            this.selectedProdutoEquivalente = this.selectedDefaultEquivalentes[0];
        } else {
            //se houver produto selecionado e a passagem de campo for via TAB no último campo anterior
            //a tabela de equivalentes então selecionar o primeiro produto da lista novamente
            this.selectedProdutoEquivalente = this.selectedDefaultEquivalentes[0];
        }
    }

    /**
     * Navegar no dataTable dos Produtos Equivalentes selecionando os elementos | SELECAO-DATA-TABLE-SERVICE.TS Melhorado e Adaptado para esta solução
     *
     */
    onKeydownDivEquivalente(event) {
        /* KeyCode = seta para baixo */
        if (event.keyCode === 40) {
            event.preventDefault();
            if (document.getElementById('irmaoEquivalente')) {
                if (event.target.parentElement.parentElement.parentElement.nextElementSibling) {

                    document.getElementById('irmaoEquivalente').style.backgroundColor = "";
                    document.getElementById('primoEquivalente').id = "";
                    document.getElementById('irmaoEquivalente').id = "";

                    event.target.parentElement.parentElement.parentElement.nextElementSibling.id = 'irmaoEquivalente';
                    let a = event.target.parentElement.parentElement.parentElement.nextElementSibling.children[0];
                    let b = a.children[1];
                    b.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }

            } else {
                if (event.target.parentElement.parentElement.parentElement.nextElementSibling) {
                    event.target.parentElement.parentElement.parentElement.nextElementSibling.id = 'irmaoEquivalente';
                    let a = event.target.parentElement.parentElement.parentElement.nextElementSibling.children[0];
                    let b = a.children[1];
                    b.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }
            }
        }
        /* KeyCode = seta para a cima */
        if (event.keyCode === 38) {
            event.preventDefault();
            if (document.getElementById('irmaoEquivalente') && document.getElementById('primoEquivalente')) {

                if (event.target.parentElement.parentElement.parentElement.previousElementSibling) {
                    document.getElementById('irmaoEquivalente').style.backgroundColor = "";
                    document.getElementById('primoEquivalente').id = "";
                    document.getElementById('irmaoEquivalente').id = "";

                    event.target.parentElement.parentElement.parentElement.previousElementSibling.id = 'irmaoEquivalente';
                    let a = event.target.parentElement.parentElement.parentElement.previousElementSibling.children[0];
                    let b = a.children[1];
                    b.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }

            } else {
                if (event.target.parentElement.parentElement.parentElement) {
                    event.target.parentElement.parentElement.parentElement.id = 'irmaoEquivalente';
                    let a = event.target.parentElement.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }
            }
        }
        /* KeyCode = Shift + TAB */
        if (event.shiftKey == true && event.keyCode === 9) {

            if (document.getElementById('irmaoEquivalente') && document.getElementById('primoEquivalente')) {
                if (event.target.parentElement.parentElement.parentElement.previousElementSibling) {
                    document.getElementById('irmaoEquivalente').style.backgroundColor = "";
                    document.getElementById('primoEquivalente').id = "";
                    document.getElementById('irmaoEquivalente').id = "";

                    event.target.parentElement.parentElement.parentElement.previousElementSibling.id = 'irmaoEquivalente';
                    let a = event.target.parentElement.parentElement.parentElement.previousElementSibling.children[0];
                    let b = a.children[1];
                    b.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                } else {
                    document.getElementById('irmaoEquivalente').style.backgroundColor = "";
                    document.getElementById('primoEquivalente').id = "";
                    document.getElementById('irmaoEquivalente').id = "";
                }
            } else {
                if (event.target.parentElement.parentElement.parentElement.previousElementSibling) {
                    event.target.parentElement.parentElement.parentElement.previousElementSibling.id = 'irmaoEquivalente';
                    let a = event.target.parentElement.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }
            }
            return;
        }
        /* KeyCode = TAB */
        if (event.keyCode === 9) {
            if (document.getElementById('irmaoEquivalente')) {
                if (event.target.parentElement.parentElement.parentElement.nextElementSibling) {
                    document.getElementById('irmaoEquivalente').style.backgroundColor = "";
                    document.getElementById('primoEquivalente').id = "";
                    document.getElementById('irmaoEquivalente').id = "";

                    event.target.parentElement.parentElement.parentElement.nextElementSibling.id = 'irmaoEquivalente';
                    let a = event.target.parentElement.parentElement.parentElement.nextElementSibling.children[0];
                    let b = a.children[1];
                    b.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }
            } else {
                if (event.target.parentElement.parentElement.parentElement.nextElementSibling) {
                    event.target.parentElement.parentElement.parentElement.nextElementSibling.id = 'irmaoEquivalente';
                    let a = event.target.parentElement.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }
            }
        }
        /* KeyCode = seta para a direita - passa para a página seguinte do grid*/
        if (event.keyCode === 39) {

            let paginator = document.getElementsByClassName('ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active');

            if (paginator[0].nextElementSibling) {
                paginator[0].nextElementSibling.id = 'paginator';
                document.getElementById('paginator').click();
                paginator[0].nextElementSibling.id = '';
            }
        }
        /* KeyCode = seta para a esquerda - passa para a página anterior do grid */
        if (event.keyCode === 37) {

            let paginator = document.getElementsByClassName('ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active');

            if (paginator[0].previousElementSibling) {
                paginator[0].previousElementSibling.id = 'paginator';
                document.getElementById('paginator').click();
                paginator[0].previousElementSibling.id = '';
            }
        }
        /* KeyCode = Enter - Selecionar produto */
        if (event.keyCode === 13) {
            this.onSelectEquivalente(event);
        }
    }

    selectedFirstRow() {
        let arr = document.getElementsByClassName('btn-transparent');
        arr[0].className = "btn-transparent selected-line";
        arr[0].id = "primeiro-item";
        document.getElementById("primeiro-item").click();
        document.getElementById("inputPesquisaProduto").focus();
    }

    elementFocus(event, t) {
        let target: any = t;
        document.querySelector(target).focus();
        if (event != null) event.preventDefault();
    }

    keyPressEnter(event, row, quantidade): void {
        if (event.key == 'Enter')
            this.onSelect(event, row, quantidade);
    }

    ngAfterViewInit() {

       this.removerCssEditor();

    }

    /*
    * Controla qual guia lateral do componente será mostrada na tela baseada nos eventos de clique do usuário
    */
    alternarGuiaLateral(nomeGuia: string): void{

        this.removerCssEditor();

        if(nomeGuia == 'informacoes'){
            this.mostrarInformacoes = true;
            this.mostrarImagens = false;
        }
        if(nomeGuia == 'imagens'){
            this.mostrarInformacoes = false;
            this.mostrarImagens = true;
        }
    }

    removerCssEditor(): void{
        let interval = setInterval(
            () => {
                let editorCabecalho = document.querySelector('.ui-editor-toolbar');
                let editorConteudo = document.querySelector('.ui-editor-content');
                if(editorCabecalho){
                    editorCabecalho.classList.add('d-none');
                    editorConteudo.classList.add('cssEditor');
                    clearInterval(interval);
                }
            }, 50
        );
    }

    /*
    * Métodos que controlam a mudança de foto do produto
    * Utiliza-se do índice do ngFor para dar o efeito de carrossel
    * */

    carregarFotoAnterior(indice: number, tamanhoArrayImagens: number): void{

        if(indice == 0){
            this.imagemSelecionada = tamanhoArrayImagens - 1;
        }
        else{
            this.imagemSelecionada -= 1;
        }
    }


    carregarFotoPosterior(indice: number, tamanhoArrayImagens: number): void{

        if(indice == tamanhoArrayImagens - 1){
            this.imagemSelecionada = 0;
        }
        else{
            this.imagemSelecionada += 1;
        }
    }

}
