
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProdutoConsulta } from '../../comercial/pedido/produto-consulta.model';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { ProdutoValida } from './produto-valida.model';
import { Produto } from './produto.model';
import { ProdutoCadastro } from './produto-cadastro-model';




@Injectable()
export class ProdutoService extends VsCrudService<Produto> {

  public url: string = "api/produtos";
  pageResult: PageResult = new PageResult();

  constructor(_http: HttpClient, _message: MessageService) {
    super(<any>_http, _message);
  }

  public findByCodigoInterno(codigo: Number) {
    return this.get(`${this.url}/interno/${codigo}`).pipe(map(res => <Produto>res));
  }

  public findByCodigo(codigo: Number) {
    return this.get(`${this.url}/codigo/${codigo}`).pipe(map(res => <Produto>res));
  }

  public clone(model: Produto): Observable<Response> {
    return this.post(this.url + '/clone', model);
  }

  public findByOutroCodigo(codigo: String) {
    return this.get(`${this.url}/outroCodigo/${codigo}`).pipe(map(res => <Produto>res));
  }

  public findByProdutoAplicacao(aplicacao: string) {
    return this.get(`${this.url}/aplicacao/${aplicacao}`).pipe(map(res => <Produto>res));
  }

  public productListAll(search: SearchParams) {
    let self = this;
    return this.search(this.url + '/searchAll', search)
      .subscribe(res => {
        self.pageResult = res;
      });
  }

  public findByCodigoBarra(codigoBarra: string) {
    return this.get(`${this.url}//codigoBarra/${codigoBarra}`).pipe(map(res => <Produto>res));

  }


  public listPersonalizado(search: SearchParams) {
    let self = this;
    //searchPersonalizada
    
    //searchByVendaProduto
    return this.search(this.url + '/searchByVendaProduto', search)
      .subscribe(res => {
        self.pageResult = res;
      });
  }

  public listPedidoOnline(search: SearchParams) {

    return this.search(this.url + '/searchByVendaProduto', search)
      
  }

  public searchByVendaPedidoOnline(search: SearchParams) {

    return this.search(this.url + '/searchByVendaPedidoOnline', search)
      
  }

  public listProdutoVeiculoPersonalizado(search: SearchParams) {
    let self = this;

    return this.search(this.url + '/searchByProdutoVeiculo', search)
      .subscribe(res => {
        self.pageResult = res;
      });
  }




  public listPersonalizadoServico(search: SearchParams) {
    let self = this;
    return this.search(this.url + '/searchPersonalizadaServico', search)
      .subscribe(res => {
        self.pageResult = res;
      });
  }

  public listEquivalente(search: SearchParams) {
    let self = this;
    return this.search(this.url + '/searchEquivalente', search)
      .subscribe(res => {
        self.pageResult = res;
      });
  }

  public importarArquivoXLS(arquivo: string) {
    return this.get(`${this.url}/importarXLS/${arquivo}`)
      .toPromise()
      .then(res => <Boolean>res);

  }

  public findByCodigoProdutoVenda(idLoja, idListaPreco, tipoCodigoProduto: Number,
    codigo: String) {
    return this.get(`${this.url}/findByCodigoProdutoVenda/${idLoja}/${idListaPreco}/
                                                      ${tipoCodigoProduto}/${codigo}`).pipe(map(res => <ProdutoConsulta>res));
  }

  public listProdutoCTA(search: SearchParams) {
    let self = this;
    return this.search(this.url + '/searchImportCompra', search)
      .subscribe(res => {
        self.pageResult = res;
      });
  }

  public findByVenda(idLoja, idProduto, idCliente: Number) {
    return this.get(`${this.url}/findByVenda/${idLoja}/${idProduto}/
                       ${idCliente}`).pipe(map(res => <ProdutoValida>res));
  }


  public saveList(arrayProduto: Array<ProdutoCadastro>) {
    return this.post(`${this.url}/saveList`, arrayProduto);
   
}



}


