import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyMaskModule } from "ngx-currency-mask";
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InputMaskModule } from 'primeng/inputmask';
import {
  AccordionModule, ButtonModule,
  CheckboxModule, ConfirmDialogModule, DialogModule, DropdownModule,
  EditorModule, InputSwitchModule,
  InputTextareaModule, InputTextModule,
  LightboxModule, ListboxModule, PaginatorModule, PanelModule, PasswordModule,
  ProgressBarModule, RadioButtonModule,
  SharedModule,
  TabViewModule, ToolbarModule,
  TreeModule,
  TreeTableModule
} from "primeng/primeng";
import { TableModule } from 'primeng/table';
import { BreadcrumbComponent } from "./shared/componentes/breadcrumb/breadcrumb.component";
import { CadastroComponent } from "./shared/componentes/cadastro/cadastro.component";
import { PesquisaAvancadaClienteComponent } from './shared/componentes/pesquisa-avancada-cliente/pesquisa-avancada-cliente.component';
import { PesquisaAvancadaPDVPagamentoComponent } from './shared/componentes/pesquisa-avancada-pdv-pagamento/pesquisa-avancada-pdv-pagamento.component';
import { PesquisaAvancadaProdutoPdvComponent } from './shared/componentes/pesquisa-avancada-produto-pdv/pesquisa-avancada-produto-pdv.component';
import { PesquisaAvancadaProdutoPedidoOnlineComponent } from './shared/componentes/pesquisa-avancada-produto-pedido-online/pesquisa-avancada-produto-pedido-online.component';
import { PesquisaAvancadaProdutoComponent } from './shared/componentes/pesquisa-avancada-produto/pesquisa-avancada-produto.component';
import { PesquisaAvancadaServicoComponent } from './shared/componentes/pesquisa-avancada-servico/pesquisa-avancada-servico.component';
import { AsInputNumberDirective } from "./shared/diretiva/as-input-number.directive";
import { AsInputTextDirective } from "./shared/diretiva/as-input-text.directive";
import { EventosService } from './shared/service/eventos.service';


@NgModule({
  declarations: [
    BreadcrumbComponent,
    PesquisaAvancadaPDVPagamentoComponent,
    PesquisaAvancadaClienteComponent,
    PesquisaAvancadaProdutoComponent,
    PesquisaAvancadaServicoComponent,
    PesquisaAvancadaProdutoPdvComponent,
    PesquisaAvancadaProdutoPedidoOnlineComponent,
    CadastroComponent,
    AsInputNumberDirective,
    AsInputTextDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    InputTextModule,
    InputSwitchModule,
    InputMaskModule,
    DropdownModule,
    AutoCompleteModule,
    ButtonModule,
    TableModule,
    SharedModule,
    ProgressBarModule,
    ConfirmDialogModule,
    PasswordModule,
    InputTextareaModule,
    CheckboxModule,
    EditorModule,
    PanelModule,
    PaginatorModule,
    CalendarModule,
    DialogModule,
    ListboxModule,
    ToolbarModule,
    AccordionModule,
    TabViewModule,
    TreeModule,
    TreeTableModule,
    CurrencyMaskModule,
    GalleriaModule,
    FileUploadModule,
    LightboxModule,
    EditorModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    InputTextModule,
    InputSwitchModule,
    InputMaskModule,
    DropdownModule,
    AutoCompleteModule,
    ButtonModule,
    TableModule,
    SharedModule,
    ConfirmDialogModule,
    PasswordModule,
    InputTextareaModule,
    CheckboxModule,
    EditorModule,
    PanelModule,
    ProgressBarModule,
    PaginatorModule,
    CalendarModule,
    DialogModule,
    ListboxModule,
    ToolbarModule,
    AccordionModule,
    TabViewModule,
    TreeModule,
    TreeTableModule,
    BreadcrumbComponent,
    PesquisaAvancadaClienteComponent,
    PesquisaAvancadaProdutoComponent,
    PesquisaAvancadaServicoComponent,
    PesquisaAvancadaPDVPagamentoComponent,
    PesquisaAvancadaProdutoPdvComponent,
    PesquisaAvancadaProdutoPedidoOnlineComponent,
    CadastroComponent,
    AsInputNumberDirective,
    AsInputTextDirective,
    GalleriaModule,
    FileUploadModule,
    LightboxModule,
    EditorModule
  ],
  providers: [EventosService],
  bootstrap: []

})
export class AraguaiaSharedModule {

}



