import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MessageService } from '../../../shared/service/message.service';
import { SearchParams } from '../../../shared/service/generic.service';
import { LazyLoadEvent, FilterMetadata } from '../../../shared/primeng/common/api';
import { VsCrudService } from '../../../shared/service/vs-crud.service';
import { GenericModel } from '../../../shared/model/generic.model';

@Component({
  selector: 'as-cadastro',
  templateUrl: './cadastro.component.html',
})
export class CadastroComponent<T extends GenericModel> implements OnInit {

  @Input()
  public title: string;

  @Input()
  public id: string;

  @Input()
  public cancelLabel: string = 'Cancelar';

  @Input()
  public saveLabel: string = 'Salvar';

  @Input('service')
  public _service: VsCrudService<T>;

  @Input('backRoute')
  public backRoute: string;

  @Output()
  public onBeforeSave: EventEmitter<any> = new EventEmitter();

  @Output()
  public onInvalid: EventEmitter<any> = new EventEmitter();

  @Input('formGroup')
  public formGroup: FormGroup;

  public _model: T;

  @Output()
  public modelChange: EventEmitter<any> = new EventEmitter();

  @Input('model')
  public set model(value: T) {
    this._model = value;
    this.modelChange.next(value);
  }

  public get model(): T {
    return this._model;
  }

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private _messages: MessageService) {
  }


  ngOnInit() {

      this._route.params.forEach((params: Params) => {
            this.id = String(+params['id']);
        });
        
        if (this.id == 'new') {            
            this._model = <T>{};
        }
        else {
            this._service.findOne(Number(this.id)).subscribe(model => {
                this.model = model;
                this.formGroup.patchValue(this.model);   
            });
        }
  }

  public limpar() {
      this._model = <T>{};          
      this.modelChange.next(this._model);  
      this.formGroup.reset();
  }

  public cancel() {
    this.back();
  }

  
  public save() {

      this.model = this.formGroup.value;

      this.onBeforeSave.next(null);

      if (this.formGroup.valid) { 
        this._service.save(this.model).subscribe(res => {
          this._messages.success("Registro salvo com sucesso!");               
        });
      }
      else {
        this._messages.info("Para salvar preencha os campos assinalados");
        this.onInvalid.next(null);
      } 

     this.limpar();
  }

  public back() {
    this._router.navigate([this.backRoute]);
  }
}