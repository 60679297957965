import {GenericModel} from '../../shared/model/generic.model';

import { Permissao } from '../permissao/permissao.model';

export class UsuarioLogado extends GenericModel {

    public id: number;
    public pessoaId: number;
    public lojaId: number;
    public grupoLojaId: number;
    public pessoaNomeFantasia: string = '';
    public pessoaRazaoSocial: string = '';
    public pessoaFisicaId  : number;
    public pessoaFisicaCpf: string;
    public pessoaFisicaIdentidade: string;
    public pessoaFisicaOrgaoExpedidor: string;
    public pessoaFisicaDataNascimento: Date;
    public pessoaEnderecos: Array<any>;
    public pessoaTelefones: Array<any>;
    public pessoaEnderecosWeb: Array<any>;
    public permissao: Permissao;
    public caminhoImagem :string;
    public nomeImagem    :string;
    public tamanhoImagem :number;
    public login: string;
    public senha: string;
    public token:string;
    public colaboradorId :number;
    public colaboradorPessoaNomeFantasia :string;
    public favorecidoId :number;
    public favorecidoPessoaNomeFantasia :string;

}
