import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Produto } from '../produto/produto.model';


@Injectable()
export class ServicoService extends VsCrudService<Produto> {

  public url: string = "api/produtos";
  pageResult: PageResult = new PageResult();

  constructor(_http: HttpClient, _message: MessageService) {
    super(<any>_http, _message);
  }

  public listPersonalizadoServico(search: SearchParams) {
    let self = this;
    return this.search(this.url + '/searchPersonalizadaServico', search)
        .subscribe(res => {
            self.pageResult = res;
        });
  }


}


