import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from "./shared/auth/auth.guard";
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from "./login/login.component";
import { ViewComponent } from './view/view.component';
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';

const routes: Routes = [
  { path: '',                             redirectTo: '/dashboard', pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'login',                        component: LoginComponent , canActivate: [AuthGuard]},
  { path: 'dashboard',                    component: DashboardComponent , canActivate: [AuthGuard]},
  { path: 'view',                         component:     ViewComponent } ,
  { path: 'administrativo',               loadChildren: () => import('../app/administrativo/administrativo.module').then(m => m.AdministrativoModule),canActivate: [AuthGuard]} ,
  { path: 'comercial',                    loadChildren: () => import('../app/comercial/comercial.module').then(m => m.ComercialModule),canActivate: [AuthGuard]},
  { path: 'financeiro',                   loadChildren: () => import('../app/financeiro/financeiro.module').then(m => m.FinanceiroModule),canActivate: [AuthGuard]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
