import { GenericModel } from '../../shared/model/generic.model';
import { ProdutoPreco }from './produto-preco.model';
import { ProdutoEstoque} from './produto-estoque.model';
import { ProdutoImagem } from './produto-imagem.model'

import { ProdutoEquivalente } from './produto-equivalente.model'
import { ProdutoInformacoes } from './produto-informacoes.model';
import { ProdutoFornecedor } from './produto-fornecedor.model';
import { GradeDinamica } from './grade-dinamica.model';


export class Produto extends GenericModel {
    
    public id: number;
    public codigoInterno    : number;
    public codigoBarra      : string = '';
    public codigoReferencia : string = '';
    public tipoProduto   : string;
    public codigoOriginal: string = '';
    public outroCodigo: string = '';
    public codigoAnp: string='';
    public aplicacao: string = '';
    public descricao: string = '';
    public descricaoAnp: string = '';
    public descricaoFiscal: string = '';
    public grupoTributoProdutoId:number;
    public grupoTributoProdutoDescricao:string='';
    public origemProdutoId:number;
    public origemDescricao:string='';
    public origemProdutoOrigem='';
    public ncmId:number;
    public ncmCodigo:string;
    public ncmDescricao:string;
    public marcaId: number;
    public marcaDescricao: string = '';
    public fabricanteId: number;
    public fabricanteDescricao: string = '';
    public categoriaId: number;
    public categoriaDescricao: string = '';
    public unidadeId: number;
    public unidadeDescricao: string = '';
    public unidadeCompraId: number;
    public unidadeCompraDescricao: string = '';
    public gradeId: number;
    public gradeDescricao: string = '';
    public gradeItemId: number ;
    public gradeItemVariacaoLinha  : string = '';
    public gradeItemVariacaoColuna : string = '';    
    public pesoLiquido: number;
    public pesoBruto: number ;
    public comprimento: number;
    public largura: number;
    public altura: number;    
    public estoques: Array<ProdutoEstoque>;
    public produtoPrecos: Array<ProdutoPreco>; 
    public produtoImagens: Array<ProdutoImagem>;
    public produtoEquivalentes: Array<ProdutoEquivalente>;
    public produtoFornecedores: Array<ProdutoFornecedor>;
    public informacoes: Array<ProdutoInformacoes>;
    public dataCadastro: Date;

    public gradeDinamica: Array<GradeDinamica>;
    
    public status:string;
    public caminhoImagem :string;
    public tamanhoImagem:any;
	public sincronizarEstoque:string;

}
