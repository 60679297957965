import { GenericModel } from "../../shared/model/generic.model";

export class ProdutoValida extends GenericModel {

    public  grupoTributoProdutoId: number;
	
	public  grupoTributoClienteId: number;
	
	public  convenioId: number;
	
	public  clienteUf: string;
	
	public  lojaId:number;
	
	public  ncmId: number;


}