import { Component,Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SearchParams } from '../../service/generic.service';
import { KEY_CODE } from '../../constantes/key-codes';
import { LazyLoadEvent } from 'primeng/components/common/api';
import { QtdeRegistrosDataTable } from '../../constantes/qtde-registros-data-table';
import { TipoStatus } from '../../constantes/tipo-status';
import { ProdutoCTAModel } from '../../../comercial/pedido/produto-cta-model';
import { ProdutoService } from '../../../estoque/produto/produto.service';
import { Produto } from '../../../estoque/produto/produto.model';
import { MessageService } from '../../service/message.service';
import { ProdutoConsulta } from '../../../comercial/pedido/produto-consulta.model';
import { ProdutoValida } from '../../../estoque/produto/produto-valida.model';
import { UnidadeService } from '../../../estoque/unidade/unidade.service';

@Component({
    selector: 'p-tabView-pesquisa-avancada-produto',
    styleUrls: ['./pesquisa-avancada-produto.component.css'],
    templateUrl: './pesquisa-avancada-produto.component.html'
})
export class PesquisaAvancadaProdutoComponent {
    
    public searchProduto : SearchParams = new SearchParams();
    public selectedProduto : ProdutoCTAModel = new ProdutoCTAModel();
    public selectedProdutoAux : ProdutoCTAModel = new ProdutoCTAModel();
    public selectedProdutoEquivalente = Object();
    public modelProdutoConsulta: ProdutoConsulta = new ProdutoConsulta();
    public modelProdutoValido: ProdutoValida = new ProdutoValida();
    public modelProduto        : ProdutoCTAModel = new ProdutoCTAModel();
    public pesquisaForm: FormGroup;
    public equivalentesForm: FormGroup;
    public navigation : boolean = false;
    public quantidadePesquisa : number = 0;
    public selectedDefault = [];
    private selectedDefaultEquivalentes = [];
    public index : number;
    public onProduto : boolean = false; //utilizado no HTML
    public onProdutoEquivalente : boolean = false;
    public  descricaoProduto : String = 'Produto';
    public pesquisaProdutoEstoque: number;
    public pesquisaProdutoEstoquePendente: number;
    
    @Input()  lojaId;
    @Input()  quantLojas;
    @Input()  favorecidoId;
    @Output() retornoTipoPesquisa = new EventEmitter();
    @Output() retornoProdutoSelecionado = new EventEmitter();
    @Output() retornoProdutoEquivalenteSelecionado = new EventEmitter();

    
    constructor(    public fb: FormBuilder,
                    public _produtoService: ProdutoService,
                    public _unidadeservice: UnidadeService,
                    public _messages: MessageService
    ) {
        this.buildPesquisaForm();
        this.buildEquivalentesForm();
    }

    buildPesquisaForm(): void {
        this.pesquisaForm = this.fb.group({
            'id':[''],
            'pesquisaProduto': [''],
            'aplicacao': [''],
            'unidadeSigla': [''],
            'categoriaDescricao': [''],
            'origemProdutoDescricao': [''],
            'ncmDescricao':[''],
            'outroCodigo':[''],
            'codigoBarra':['']
        });
    }

    buildEquivalentesForm(): void {
        this.equivalentesForm = this.fb.group({
            'equivalenteAplicacao':[''],
            'equivalenteDescricao': [''],
            'equivalenteCodigoReferencia': [''],
            'equivalenteCodigoOriginal': ['']
        });
    }

    loadProdutos(event: LazyLoadEvent) {
        //Calcula a página atual
        let page = 0; 
        this.quantidadePesquisa++;

        if (event.first > 0) {
            page = event.first / event.rows;
        }
        
        this.searchProduto.page = page;
        this.searchProduto.size = QtdeRegistrosDataTable.QTDE;
        this.searchProduto.filters["pesquisa"];
        this.searchProduto.filters['status'] = TipoStatus.ATIVO;
        this.searchProduto.sorting[event.sortField] = event.sortOrder == -1 ? 'desc' : 'asc';
        this._produtoService.listPersonalizado(this.searchProduto);

        if(page >= 0){
            //Fica ouvindo o resultado da seleção do Produto Default
            var interval = setInterval(() =>{
                this.selectedDefault = this._produtoService.pageResult.content;
                if(this.selectedDefault.length > 0 ){ 
                    clearInterval(interval);                   
                    // Set default row selection in first row DataTable Produtos
                    this.selectedProduto = this.selectedDefault['0'];
                    this.onProduto = true;
                    this.onProdutoEquivalente = false;
                    this.index = 0;
                    this.selectedFirstRow();      
                }
            },600); 
        }
    }

    onPesquisaProduto(event) {
        /* Event diferente de SHIFT, TAB e SHIFT + TAB */
        if(event.keyCode != KEY_CODE.TECLA_SHIFT){
            if(event.keyCode != KEY_CODE.TECLA_TAB && event.keyCode != KEY_CODE.TECLA_SETA_PARA_BAIXO && event.keyCode != KEY_CODE.TECLA_ENTER){
                if(event.shiftKey != true && event.keyCode != KEY_CODE.TECLA_TAB){
                    this.navigation = false;
                    this.loadProdutos(event); 
                }
            }else{
                if(event.keyCode == KEY_CODE.TECLA_TAB){
                    let table:any = '.selected-line';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    event.preventDefault();
                }
                if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_BAIXO){
                    let table:any = '.selected-line';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    event.preventDefault();
                }
                if(event.keyCode == KEY_CODE.TECLA_ENTER){
                    let table:any = '.selected-line';
                    document.querySelector(table).click();
                    document.querySelector(table).focus();
                    this.onSelect(event);
                    event.preventDefault();
                }
            }
        }
    }

    //EVENT EMITTER
    //selecionar elemento no dataTable e enviar a seleção para a Tela de Pesquisa Simples (input:search)
    onSelect(event):void{
        this.selectedProduto = this.selectedProdutoAux['0'];
        if(this.selectedProduto != null){
            if(this.favorecidoId == null){
                this._messages.warn("Insira o nome do Cliente antes de selecionar o produto.");
                this.elementFocus(event,"#favorecidoPessoaNomeFantasia > span > input");
            }else{
                this.pesquisaForm.get('pesquisaProduto').setValue('');
                this.retornoProdutoSelecionado.emit(this.selectedProduto);
                this.retornarPesquisaSimples();
            }
        }else{
            console.log("ERRO AO SELECIONAR O PRODUTO - Descrição do produto vazia");
        }  
    }

    //EVENT EMITTER
    //selecionar produto equivalente no dataTable e enviar a seleção para a Tela de Pesquisa Simples (input:search)
    onSelectEquivalente(event):void{
        this.onProdutoEquivalente = true;
        if(this.selectedProdutoEquivalente['equivalenteDescricao'] != ''){
            if(this.favorecidoId == null){
                this._messages.warn("Insira o nome do Cliente antes de selecionar o produto.");
                this.elementFocus(event,"#favorecidoPessoaNomeFantasia > span > input");
            }else{
                
                this._produtoService.findOne(Number(this.selectedProdutoEquivalente['equivalenteId'])).subscribe(model => {
                    this.selectedProdutoEquivalente = model;
                    this.retornoProdutoEquivalenteSelecionado.emit(this.selectedProdutoEquivalente);
                    this.retornarPesquisaSimples();
                });
            }
        }else{
            console.log("ERRO AO SELECIONAR O PRODUTO EQUIVALENTE - Descrição do produto vazia");
        }
    }

    //EVENT EMITTER
    retornarPesquisaSimples(){
        this.retornoTipoPesquisa.emit({pesquisa:false});
    }

    onClickProduto(event){
        
        let button;
        let alvo = event.target;
        let buttonSelected;

        this.onProduto = true; //mostrar informações completas do produto
        this.onProdutoEquivalente = false; //se houver seleção do equivalente anteriormente, retirar suas informações da tela
        this.selectedProdutoEquivalente = new Produto; //reset produto equivalente selecionado
        this.selectedProduto = this.selectedProdutoAux['0'];
        //console.log(alvo);
        switch(alvo.nodeName){
            case 'BUTTON':
                console.log("BUTTON");
                button = alvo.parentElement;
                button.click();

            break;
            case 'I':
                console.log("I");
                button = alvo.parentNode;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if(buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
                if(this.selectedProduto.produtoEquivalentes){
                    this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
                }
            break;
            case 'DIV':
                console.log("DIV");
                button = alvo.parentNode.parentNode.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if(buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
                if(this.selectedProduto.produtoEquivalentes){
                    this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
                }
            break;
            case 'TD' :
                console.log("TD");
                button = alvo.parentNode.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if(buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
                if(this.selectedProduto.produtoEquivalentes){
                    this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
                }
            break;
            case 'SPAN' :
                console.log("SPAN");
                button = alvo.parentNode.parentElement.firstElementChild.lastElementChild;
                if(button.nodeName == 'SPAN') button = alvo.parentNode.parentNode.parentElement.firstElementChild.lastElementChild;
                button.focus();
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                buttonSelected = document.querySelector(".selected-line");
                if(buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
                if(this.selectedProduto.produtoEquivalentes){
                    this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
                }
            break;
            case 'P-TABLE':
                console.log("P-TABLE");
                if(alvo.firstElementChild.firstElementChild.firstElementChild != null){
                    //Primeiro clique : O evento possui a navegação indicada
                    button = alvo.firstElementChild.firstElementChild.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild;
                    button.focus();
                    
                }else{
                    //Segundo clique em diante : O evento não possui a primeira navegação indicada e sim esta
                    button = alvo.firstElementChild.firstElementChild.nextElementSibling.nextElementSibling.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild;
                    button.focus();
                }
                //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                //buttonSelected = document.querySelector(".selected-line");
                //if(buttonSelected) buttonSelected.className = 'btn-transparent';
                //insere a classe que identifica que a linha do grid está selecionada
                button.className = "btn-transparent selected-line";
                if(this.selectedProduto.produtoEquivalentes){
                    // Comentei por enquanto
                    // this.loadEquivalente(this.selectedProduto.produtoEquivalentes[0].produtoId)
                    this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
                }
                
            break;
            default:
                console.log("Default: ",alvo.nodeName);

            if(this.selectedProduto.descricao != null) this.descricaoProduto = this.selectedProduto.descricao;
            
        }
    }

    onClickEquivalente(event){
        let button;
        let alvo = event.target;

        this.onProduto = false; //se houver seleção do produto anteriormente, retirar suas informações da tela
        this.onProdutoEquivalente = true; //mostrar informações completas do produto equivalente
        if(this.onProdutoEquivalente == true){
            console.log("Produto Equivalente Selecionado:", this.selectedProdutoEquivalente);
        }
        //console.log(alvo);
        switch(alvo.nodeName){
            case 'SPAN': 
                button = alvo.parentNode.parentNode.firstElementChild.lastElementChild.lastElementChild;
                button.focus();
            break;
            case 'TD' :
                button = alvo.parentNode.firstElementChild.lastElementChild.lastElementChild;
                button.focus();
            break;
            case 'P-DATATABLE':
                button = alvo.firstElementChild.firstElementChild.firstElementChild.lastElementChild.firstElementChild.firstElementChild.lastElementChild.lastElementChild;
                button.focus();
            break;
        }
    } 

    /**
     * Navegar no dataTable selecionando os elementos | SELECAO-DATA-TABLE-SERVICE.TS Melhorado e Adaptado para esta solução
     *
     */
    onKeydownDiv(event) {
        let buttonSelected;
        let proxButton;
        let prevButton;

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_BAIXO) {
            event.preventDefault();
            if (document.getElementById('irmao')){
                if(event.target.parentElement.parentElement.nextElementSibling){
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }        
            }else{
                if(event.target.parentElement.parentElement.nextElementSibling){
                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            } 
        }

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_CIMA) {
            event.preventDefault();
            if (document.getElementById('irmao')){                              
                if(event.target.parentElement.parentElement.previousElementSibling){
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
             
            }else{
                if(event.target.parentElement.parentElement){
                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }      
        }

        if(event.shiftKey == true && event.keyCode == KEY_CODE.TECLA_TAB){
            event.preventDefault();
            if (document.getElementById('irmao')){                              
                if(event.target.parentElement.parentElement.previousElementSibling){
                   
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }else{
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";
                }
            }else{
                if(event.target.parentElement.parentElement.previousElementSibling){
                   
                    prevButton = event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild;
                    prevButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    prevButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }     
            return;
        }

        if(event.keyCode == KEY_CODE.TECLA_TAB){
            event.preventDefault();
            if (document.getElementById('irmao')){
                if(event.target.parentElement.parentElement.nextElementSibling){

                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('irmao').id = "";

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
            }else{
                if(event.target.parentElement.parentElement.nextElementSibling){ 

                    proxButton = event.target.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild;
                    proxButton.id = 'irmao';
                    //verifica se já há alguma linha selecionada e reseta a seleção quando há um click em outro elemento
                    buttonSelected = document.querySelector(".selected-line");
                    if(buttonSelected) buttonSelected.className = 'btn-transparent';
                    //insere a classe que identifica que a linha do grid está selecionada
                    proxButton.className = "btn-transparent selected-line";
                    document.getElementById('irmao').click();
                    document.getElementById('irmao').focus();
                }
             }
        }

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_DIREITA){
            let prox = document.getElementsByClassName('ui-paginator-next');
            prox[2].id = 'proxProduto';
            document.getElementById('proxProduto').click();
        }

        if(event.keyCode == KEY_CODE.TECLA_SETA_PARA_ESQUERDA){
            let prev = document.getElementsByClassName('ui-paginator-prev');
            prev[2].id = 'prevProduto';
            document.getElementById('prevProduto').click();
        }

        if(event.keyCode == KEY_CODE.TECLA_ENTER){
            this.onSelect(event);
        }
    }

    /**
     * Seleciona a primeira linha da tabela de produtos equivalentes quando 
     * há passagem direta via TAB do último campo anterior a tabela de equivalentes
     * 
     */
    onKeydownFirstTab(event){
        if(this.selectedProdutoEquivalente.descricao == ''){
            //se não houver produto selecionado, então selecionar o primeiro da lista
            if(this.selectedProduto.produtoEquivalentes){
                //preenche a lista com os equivalentes do produto selecionado
                this.selectedDefaultEquivalentes = this.selectedProduto.produtoEquivalentes;
            }
            this.selectedProdutoEquivalente = this.selectedDefaultEquivalentes[0];
        }else{
            //se houver produto selecionado e a passagem de campo for via TAB no último campo anterior
            //a tabela de equivalentes então selecionar o primeiro produto da lista novamente
            this.selectedProdutoEquivalente = this.selectedDefaultEquivalentes[0];
        }
    }

    /**
     * Navegar no dataTable dos Produtos Equivalentes selecionando os elementos | SELECAO-DATA-TABLE-SERVICE.TS Melhorado e Adaptado para esta solução
     *
     */
    onKeydownDivEquivalente(event) {
        /* KeyCode = seta para baixo */ 
        if(event.keyCode === 40) {
            event.preventDefault();
            if (document.getElementById('irmaoEquivalente')){
                if(event.target.parentElement.parentElement.parentElement.nextElementSibling){
                    
                    document.getElementById('irmaoEquivalente').style.backgroundColor = "";
                    document.getElementById ('primoEquivalente').id = ""; 
                    document.getElementById('irmaoEquivalente').id = "";

                    event.target.parentElement.parentElement.parentElement.nextElementSibling.id ='irmaoEquivalente';
                    let a = event.target.parentElement.parentElement.parentElement.nextElementSibling.children[0];
                    let b = a.children[1];
                    b.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }
                             
            }else{
                if(event.target.parentElement.parentElement.parentElement.nextElementSibling){
                    event.target.parentElement.parentElement.parentElement.nextElementSibling.id ='irmaoEquivalente';
                    let a = event.target.parentElement.parentElement.parentElement.nextElementSibling.children[0];
                    let b = a.children[1];
                    b.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }
            } 
        }
        /* KeyCode = seta para a cima */
        if(event.keyCode === 38) {
             event.preventDefault();
            if (document.getElementById('irmaoEquivalente') && document.getElementById('primoEquivalente')){                              
                 
                if(event.target.parentElement.parentElement.parentElement.previousElementSibling){
                    document.getElementById('irmaoEquivalente').style.backgroundColor = "";
                    document.getElementById('primoEquivalente').id = ""; 
                    document.getElementById('irmaoEquivalente').id = "";

                    event.target.parentElement.parentElement.parentElement.previousElementSibling.id ='irmaoEquivalente';
                    let a = event.target.parentElement.parentElement.parentElement.previousElementSibling.children[0];
                    let b = a.children[1];
                    b.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }
             
            }else{
                if(event.target.parentElement.parentElement.parentElement){
                    event.target.parentElement.parentElement.parentElement.id = 'irmaoEquivalente';              
                    let a = event.target.parentElement.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }
            }      
        }
        /* KeyCode = Shift + TAB */
        if(event.shiftKey == true && event.keyCode === 9){
            
            if (document.getElementById('irmaoEquivalente') && document.getElementById('primoEquivalente')){                              
                if(event.target.parentElement.parentElement.parentElement.previousElementSibling){
                    document.getElementById('irmaoEquivalente').style.backgroundColor = "";
                    document.getElementById('primoEquivalente').id = ""; 
                    document.getElementById('irmaoEquivalente').id = "";

                    event.target.parentElement.parentElement.parentElement.previousElementSibling.id ='irmaoEquivalente';
                    let a = event.target.parentElement.parentElement.parentElement.previousElementSibling.children[0];
                    let b = a.children[1];
                    b.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }else{
                    document.getElementById('irmaoEquivalente').style.backgroundColor = "";
                    document.getElementById('primoEquivalente').id = ""; 
                    document.getElementById('irmaoEquivalente').id = "";
                }
            }else{
                if(event.target.parentElement.parentElement.parentElement.previousElementSibling){
                    event.target.parentElement.parentElement.parentElement.previousElementSibling.id = 'irmaoEquivalente';              
                    let a = event.target.parentElement.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }
            }     
            return;
        }
        /* KeyCode = TAB */
        if(event.keyCode === 9){
            if (document.getElementById('irmaoEquivalente')){
                if(event.target.parentElement.parentElement.parentElement.nextElementSibling){
                    document.getElementById('irmaoEquivalente').style.backgroundColor = "";
                    document.getElementById('primoEquivalente').id = ""; 
                    document.getElementById('irmaoEquivalente').id = "";
    
                    event.target.parentElement.parentElement.parentElement.nextElementSibling.id = 'irmaoEquivalente';              
                    let a = event.target.parentElement.parentElement.parentElement.nextElementSibling.children[0];
                    let b = a.children[1];
                    b.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }   
            }else{
                if(event.target.parentElement.parentElement.parentElement.nextElementSibling){
                    event.target.parentElement.parentElement.parentElement.nextElementSibling.id = 'irmaoEquivalente';              
                    let a = event.target.parentElement.children[1].id = 'primoEquivalente';
                    document.getElementById('primoEquivalente').click();
                    document.getElementById('primoEquivalente').focus();
                }
             }
        }
        /* KeyCode = seta para a direita - passa para a página seguinte do grid*/
        if(event.keyCode === 39){
            
            let paginator = document.getElementsByClassName('ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active');
            
            if(paginator[0].nextElementSibling){
                paginator[0].nextElementSibling.id = 'paginator';
                document.getElementById('paginator').click();
                paginator[0].nextElementSibling.id = '';
            }
        }
        /* KeyCode = seta para a esquerda - passa para a página anterior do grid */
        if(event.keyCode === 37){
            
            let paginator = document.getElementsByClassName('ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active');
            
            if(paginator[0].previousElementSibling){
                paginator[0].previousElementSibling.id = 'paginator';
                document.getElementById('paginator').click();
                paginator[0].previousElementSibling.id = '';
            }
        }
        /* KeyCode = Enter - Selecionar produto */
        if(event.keyCode === 13){
            this.onSelectEquivalente(event);
        }
    }

    selectedFirstRow(){
        let arr = document.getElementsByClassName('btn-transparent');
        arr[0].className = "btn-transparent selected-line";
        arr[0].id = "primeiro-item";
        document.getElementById("primeiro-item").click();
        document.getElementById("inputPesquisaProduto").focus();
    }

    elementFocus(event,t){
        let target : any = t;
        document.querySelector(target).focus();
        if(event != null) event.preventDefault();
    } 

}